import React, { Suspense, useEffect, useContext } from 'react'
import { HashRouter, BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { CSpinner, useColorModes } from '@coreui/react'
import { PrivateRoutes } from './router/PrivateRoutes'
import AuthContext from './store/AuthContext'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'))
const Login = React.lazy(() => import('./views/pages/login/Login2'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const PageCoSoKCBHome = React.lazy(() => import('./views/pages/pageCoSoKCB/PageCoSoKCBHome'))

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes('coreui-free-react-admin-template-theme')
  // const storedTheme = useSelector((state) => state.theme)
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    // const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    // const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    // if (theme) {
    //   setColorMode(theme)
    // }
    // if (isColorModeSet()) {
    //   return
    // }
    // setColorMode(storedTheme)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <Routes>
          {/* <Route exact path="/login" name="Login Page" element={<Login />} /> */}
          <Route exact path="" name="Home Page" element={authCtx.isToken ? <Navigate to="/dashboard" replace /> : <Login />} />
          <Route exact path="/login" name="Login Page" element={authCtx.isToken ? <Navigate to="/dashboard" replace /> : <Login />} />
          {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            path="*"
            name="Home"
            element={authCtx.isToken ? <DefaultLayout /> : <Navigate to="/login" replace />}
          />

          {/* <Route element={<PrivateRoutes />}>
            <Route
              exact
              path="/cskcb"
              name="Co so kham chua benh Page"
              element={<PageCoSoKCBHome />}
            />
          </Route> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
