import { legacy_createStore as createStore } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import globalSlice from './features/globalSlice';

const initialState = {
  sidebarShow: true,
  theme: 'light',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
// const store = configureStore({
//   reducer: {
//     globalReducer: globalSlice
//   }
// })
export default store
