import { Outlet, Navigate } from 'react-router-dom'
import AuthContext from 'src/store/AuthContext'
import React, {useContext} from 'react'

export const PrivateRoutes = () => {

  const authCtx = useContext(AuthContext)
  // console.log("context ", authCtx.isLoggedIn)
  return authCtx.isLoggedIn ? <Outlet /> : <Navigate to="/login" />
}
