import React, { useState } from 'react'

const AuthContext = React.createContext({
  isSidebarOpen: false,
  isLoggedIn: false,
  isToken: null,
  userInfo: null,
  token: null,
  //   webVersion: null,
  //   setWebVersion: (version) => {},
  login: (isToken, isUserInfo) => {},
  logout: () => {},
  openSide: (isSidebarOpen) => {},
})

export const AuthContextProvider = (props) => {
  const storedToken = sessionStorage.getItem('usersyt-token')
  const storedUser = JSON.parse(sessionStorage.getItem('usersyt-info'))
  let initialToken = null;
  let initialUser = null;
  let initialVersion = null;

  if (storedToken) {
    initialToken = storedToken
    initialUser = storedUser
  }

  const [isToken, setToken] = useState(storedToken)

  const [isSideOpen, setSideOpen] = useState(false)

  //note: bỏ lưu token
  // const [isToken, setToken] = useState(null);
  const [isUserInfo, setUserInfo] = useState(storedUser)

  const userIsLoggedIn = (storedToken != null || storedToken != undefined ) ? true : false

  const loginHandler = (isToken, isUserInfo) => {
    //setIsLog(isLog);
    setToken(isToken)
    setUserInfo(isUserInfo)

    sessionStorage.setItem('usersyt-token', isToken)
    // localStorage.setItem("token", isToken);
    sessionStorage.setItem(
      'usersyt-info',
      JSON.stringify(isUserInfo),
    )
  }

  const logoutHandler = () => {
    sessionStorage.removeItem('usersyt-token')
    sessionStorage.removeItem('usersyt-info')
    sessionStorage.removeItem('sidebar-open')
    setToken(null)
    setUserInfo(null)
  }

  const openSideHandler = (isSideOpen) => {
    setSideOpen(isSideOpen)
    sessionStorage.setItem('sidebar-open', isSideOpen)
  }

  const contextValue = {
    isSidebarOpen: isSideOpen,
    isLoggedIn: userIsLoggedIn,
    isToken: isToken,
    userInfo: isUserInfo,
    login: loginHandler,
    logout: logoutHandler,
    openSide: openSideHandler,
  }

  // eslint-disable-next-line react/prop-types
  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext
