import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  theme: 'light',
}

const globalSlice = createSlice({
  name: 'globalState',
  initialState,
  reducers: {
    showSidebar: (state, action) => {
    state.sidebarShow = action.payload
    },
    changeTheme: (state, action) => {
      state.theme = action.payload
    },
  },
})
//   const changeState = (state = initialState, { type, ...rest }) => {
//     switch (type) {
//       case 'set':
//         return { ...state, ...rest }
//       default:
//         return state
//     }
//   }



export const { showSidebar, changeTheme } = globalSlice.actions

export default globalSlice.reducer
